import appSettings from "./appSettings/appSettings";
import React, { useState, useEffect } from "react";
import { View, Text, ActivityIndicator, Image, ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "./styles/auctionsstyles";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AuctionLotsList from "./components/AuctionLotsList";
import ProductShowcase from "./components/productShowcase";
import DataManager from "./components/dataManager/dataManager";
import BiddingBlock from "./components/BiddingBlock";
import ChatBlock from "./components/ChatBlock";

const Auctions = () => {
  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);

  const {
    currentLiveAuction,
    currentCategory,
    publishedBy,
    currentAuctions,
    totalAuctions,
    lotsRemaining,
  } = DataManager();

  const progress = (totalAuctions - lotsRemaining) / totalAuctions;

  useEffect(() => {
    // Retrieve token from AsyncStorage
    const getToken = async () => {
      try {
        const storedToken = await AsyncStorage.getItem("token");
        if (storedToken !== null) {
          setToken(storedToken);
          // Fetch user data using the retrieved token
          await fetchUserData(storedToken);
        } else {
          setLoading(false);
          window.location.href = "/Login";
        }
      } catch (error) {
        setLoading(false);
      }
    };

    // Call the function to retrieve token
    getToken();
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(appSettings.wpApiFetchURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const userData = await response.json();
      console.log("User Data:", userData);
      setUserData(userData);
      setLoading(false);
    } catch (error) {
      window.location.href = "/Login";
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return (
      <View style={styles.centeredView}>
        <Text>No token provided</Text>
        <StatusBar style="auto" />
      </View>
    );
  }

  if (loading) {
    return (
      <View style={styles.centeredView}>
        <ActivityIndicator size="large" color="#0000ff" />
        <StatusBar style="auto" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <View style={{ flexDirection: "row", width: "100%", height: "50%" }}>
        <View style={[styles.column, styles.leftColumn]}>
          <LinearGradient
            // Use the gradient settings from appSettings
            colors={appSettings.gradient.horizontal.colors}
            start={appSettings.gradient.horizontal.start}
            end={appSettings.gradient.horizontal.end}
            style={[styles.gradientBackground, styles.topBox]}
          >
            <Image
              source={{ uri: appSettings.logoImage }}
              style={styles.logoImage}
            />
            <Text style={styles.currentAuctTitle}>{currentLiveAuction}</Text>
            <Text style={styles.currentCatTitle}>{currentCategory}</Text>
            <View style={styles.publisherArea}>
              <MaterialCommunityIcons
                name="account-outline"
                size={18}
                color={appSettings.color.Orange}
              />
              <Text style={styles.publishedByText}>{publishedBy}</Text>
            </View>
          </LinearGradient>
          <View style={styles.emptySpacer} />
          <LinearGradient
            // Use the gradient settings from appSettings
            colors={appSettings.gradient.horizontal.colors}
            start={appSettings.gradient.horizontal.start}
            end={appSettings.gradient.horizontal.end}
            style={[styles.gradientBackground, styles.bottomBox]}
          >
            <Text style={styles.lotsRemainingText}>
              <Text style={styles.boldText}>{lotsRemaining}</Text> of{" "}
              <Text style={styles.boldText}>{totalAuctions}</Text> Lots
              Remaining
            </Text>
            <View style={styles.progressBarContainer}>
              <View
                style={[styles.progressBar, { width: `${progress * 100}%` }]}
              />
            </View>
            <Text style={styles.text}>Add divider here!</Text>
            <AuctionLotsList />
          </LinearGradient>
        </View>
        <View style={[styles.column, styles.middleColumn]}>
          <ProductShowcase />
          <LinearGradient
            // Use the gradient settings from appSettings
            colors={appSettings.gradient.horizontal.colors}
            start={appSettings.gradient.horizontal.start}
            end={appSettings.gradient.horizontal.end}
            style={[styles.gradientBackground, styles.middleBottomBlock]}
          >
            <BiddingBlock />
          </LinearGradient>
        </View>
        <View style={[styles.column, styles.rightColumn]}>
          <LinearGradient
            // Use the gradient settings from appSettings
            colors={appSettings.gradient.horizontal.colors}
            start={appSettings.gradient.horizontal.start}
            end={appSettings.gradient.horizontal.end}
            style={[styles.gradientBackground, styles.middleBottomBlock]}
          >
            <ChatBlock />
          </LinearGradient>
        </View>
      </View>
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(211, 211, 211, 0.5)",
          borderRadius: 10,
          padding: 5,
          maxWidth: 200,
          maxHeight: 300,
        }}
      >
        <Text
          style={{
            color: "red",
            fontWeight: 900,
            fontSize: 10,
          }}
        >
          INFORMATIONAL BLOCK, MUST BE DELETED!
        </Text>
        <Text
          style={{
            fontSize: 11,
          }}
        >
          Welcome to Auctions Screen!
        </Text>
        {userData && (
          <View>
            <Text
              style={{
                fontSize: 11,
              }}
            >
              User Name: {userData.name}
            </Text>
            <ScrollView style={{ maxHeight: 150 }}>
              <Text
                style={{
                  fontSize: 8,
                  color: appSettings.color.darkGray,
                }}
              >
                Token: {token}
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  color: appSettings.color.darkGray,
                }}
              >
                All Data: {JSON.stringify(userData)}
              </Text>
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  );
};

export default Auctions;
