// File: ./appSettings/appSettings.js
const settings = {
  logoImage: "https://premiumauctioneer.com/wp-content/uploads/2023/11/0-1.png", // Assuming your logo is stored in the assets directory
  wpApiJWTLogin: "https://premiumauctioneer.com/wp-json/jwt-auth/v1/token",
  wpApiFetchURL: "https://premiumauctioneer.com/wp-json/wp/v2/users/me",

  color: {
    White: "#FFFFFF",
    Orange: "#DCBC04",
    OrangeHover: "#ffe867",
    Green: "#25D15F",
    Red: "#F94F46",
    Blue: "#2283F3",
    lightGray: "#C7C7C7",
    darkGray: "#848484",
    Black: "#272727",
    grayBorderColor: "#C5C5C5",
    loadBarBgGray: "#E3E3E3",
  },
  generalStyling: {
    BorderRadius: 15,
  },
  gradient: {
    horizontal: {
      colors: ["rgba(242, 242, 242, 1)", "rgba(249, 249, 249, 0.55)"],
      start: [1, 0],
      end: [0, 0],
    },
  },
};

export default settings;
