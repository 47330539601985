// lotsData.js

import appSettings from "../../appSettings/appSettings";

const lotsData = [
  {
    id: 1,
    image:
      "https://s.alicdn.com/@sc04/kf/Hfc743a5dc2db4ea4857844d1d3b5e34dt.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Unsold",
    title: "Rolex Ladies President Diamond Datejust...",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.",
    category: "Category 1",
    condition: "New",
    price: "$3,500",
    bids: "150 bids",
    publishedBy: "Roei",
  },
  {
    id: 2,
    image:
      "https://s.alicdn.com/@sc04/kf/H5737ee101ac8433d9b0f7e742daac068F.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Unsold",
    title: "1.15 Ctw Certified VS/SI Diamond Halo Ring 18kWhite Gold",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.",
    category: "Category 2",
    condition: "Old",
    price: "$52,000",
    bids: "1023 bids",
    publishedBy: "Florin",
  },
  {
    id: 3,
    image:
      "https://s.alicdn.com/@sc04/kf/Hb7d7ef746ef645b89b5f5c022976ebb4d.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Live",
    title: "1.15 Ctw Certified VS/SI Diamond Halo Ring 18kWhite Gold",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.",
    category: "Category 3",
    condition: "New",
    price: "$1,000",
    bids: "50 bids",
    publishedBy: "Vova",
  },
  {
    id: 4,
    image:
      "https://s.alicdn.com/@sc04/kf/He79680f9d3ac4aafbfe6b2f6ad65d6c9c.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Upcoming",
    title: "1.15 Ctw Certified VS/SI Diamond Halo Ring 18kWhite Gold",
    longDescription: `Van Cleef & Arpels PERRELET Small Ring \nBrand: Van Cleef & Arpels\nStyle: Ring\nMaterial: 750 White Gold\n"Gender: Ladies"\nSize: 4-4.5 (US size) (47)\nAccessories: No accessories\nMain Stone: No stones\nWeight: approx. 2.1g\nActualsize: Max W: 2.1mm\nType: Ring\nMetal: 750 White Gold\nMetal Purity: 750 White Gold\nRing Size: 4-4.5 (US size) (47)\n\nAB: Very Good\n\nThe Mynt Guarantee:\nAll items in this auction are guaranteed to be 100% authentic.\n\nAt Your Service:\nAt Mynt, our Customer Support Specialists (Click the ASK A QUESTION button beneath our Logo) are available during regular business hours to help with any questions, concerns or special requests that you may have.`,
    category: "Category 4",
    condition: "New",
    price: "$500",
    bids: "20 bids",
    publishedBy: "Peter",
  },
  {
    id: 5,
    image:
      "https://s.alicdn.com/@sc04/kf/Hf99623099d58422490b36d9283cc298dL.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Upcoming",
    title: "Product 5",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.",
    category: "Category 5",
    condition: "New",
    price: "$500",
    bids: "20 bids",
    publishedBy: "Jhon",
  },
  {
    id: 6,
    image:
      "https://s.alicdn.com/@sc04/kf/Hc9635fc5d41d4b30b04b6dc6658609dbg.jpg_250x250xz.jpg",
    secondaryImages: [
      "https://s.alicdn.com/@sc04/kf/Hfbe4476000ce4e7eb2e9098fd957677cm.jpg_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H279340bd20b34fa4a7b9b343deed7430R.png_250x250xz.jpg",
      "https://s.alicdn.com/@sc04/kf/H30ea6a975d1e4eaa828e14f217da8d0cq.jpg_250x250xz.jpg",
    ],
    status: "Upcoming",
    title: "Product 6",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.",
    category: "Category 6",
    condition: "New",
    price: "$500",
    bids: "20 bids",
    publishedBy: "Andrew",
  },
];

export default lotsData;
