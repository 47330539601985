import { useEffect } from "react";
import io from "socket.io-client";

// Assuming you're using fetch, but you can use Axios or any other HTTP client
const fetchProducts = async () => {
  try {
    const response = await fetch(
      "https://admin.premiumauctioneer.com/api/products"
    );
    if (!response.ok) {
      throw new Error("Failed to fetch products");
    }
    const data = await response.json();
    console.log("Fetched all products:", data);
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

const SERVER_URL = "https://admin.premiumauctioneer.com";

const useSocketProductUpdates = () => {
  useEffect(() => {
    const socket = io(SERVER_URL);

    socket.on("connect", () => {
      console.log("Connected to Strapi via Socket.IO");

      // Refetch products on any product update
      const handleProductUpdate = async (data) => {
        console.log("Product updated, refetching all products:", data);
        await fetchProducts(); // Call the fetch function
      };

      socket.on("product:create", handleProductUpdate);
      socket.on("product:update", handleProductUpdate);
      socket.on("product:delete", handleProductUpdate);
    });

    return () => {
      socket.off("connect");
      socket.off("product:create", handleProductUpdate);
      socket.off("product:update", handleProductUpdate);
      socket.off("product:delete", handleProductUpdate);
      socket.disconnect();
    };
  }, []);

  // No need to return anything from this hook for now
};

export default useSocketProductUpdates;
