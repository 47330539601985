import appSettings from "../../appSettings/appSettings";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  incrementContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  incrementText: {
    fontSize: 16,
    width: "20%",
    alignSelf: "flex-start",
    textAlign: "center",
  },
  bidContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
    marginTop: 3,
  },
  estimateText: {
    width: "80%",
    textAlign: "center",
    fontSize: 16,
    alignSelf: "flex-end",
  },
  bidInput: {
    width: "45%",
    borderWidth: 1,
    borderColor: appSettings.color.darkGray,
    backgroundColor: "white",
    borderRadius: 6,
    padding: 8,
    fontSize: 30,
    fontWeight: 700,
    textAlign: "center",
    color: appSettings.color.darkGray,
    marginLeft: 5,
  },
  bidIncrement: {
    width: "25%",
    borderWidth: 1,
    borderColor: appSettings.color.darkGray,
    borderRadius: 6,
    padding: 8,
    fontSize: 30,
    minWidth: 120,
    fontWeight: 700,
    textAlign: "center",
    color: appSettings.color.lightGray,
    marginRight: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    paddingLeft: 50,
    paddingRight: 50,
  },
  errorText: {
    color: "red",
    marginTop: 4,
    alignSelf: "center",
  },
  bidNowButton: {
    backgroundColor: appSettings.color.Orange,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 60,
    paddingRight: 60,
    alignSelf: "center",
  },
  bidNowButtonText: {
    color: appSettings.color.Black,
    fontSize: 15,
    fontWeight: "700",
  },
});
