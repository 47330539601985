import appSettings from "../../appSettings/appSettings";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  mainImage: {
    width: 500,
    height: 400,
    aspectRatio: 1,
    resizeMode: "cover",
    borderRadius: 10,
  },
  secondaryImagesContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  secondaryImage: {
    width: 90, // slightly smaller for unselected
    height: 90, // slightly smaller for unselected
    marginRight: 10,
    borderRadius: 10,
    opacity: 0.7, // reduced opacity for unselected
  },
  selectedSecondaryImage: {
    width: 100, // slightly bigger for selected
    height: 100, // slightly bigger for selected
    marginRight: 10,
    borderRadius: 10,
    opacity: 1, // full opacity for selected
  },
  topArea: {
    width: "100%",
    alignItems: "left",
    height: 100,
  },
  lotIdBlock: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: appSettings.color.lightGray,
    backgroundColor: "white",
    width: 100,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  lotIdText: {
    color: appSettings.color.Black,
    fontWeight: 700,
    fontSize: 15,
  },
  lotTitle: {
    fontWeight: 700,
    fontSize: 24,
    color: appSettings.color.Black,
  },
  publisherArea: {
    flexDirection: "row",
    alignItems: "flex-start",
    height: 12,
  },
  publishedByText: {
    marginLeft: 2,
    fontSize: 14,
    color: appSettings.color.Orange,
  },
  lotCategory: {
    fontWeight: 400,
    fontSize: 11,
    color: appSettings.color.darkGray,
  },
  imageContainerBlock: {
    width: "100%",
    alignItems: "center",
  },
  imageAndDesc: {
    width: "100%",
    flexDirection: "column",
    height: 600,
    paddingTop: 20,
    paddingBottom: 20,
  },
  descriptionBlock: {
    width: "100%",
    alignItems: "center",
  },
  longDescriptionTxt: {
    width: "70%",
    wordWrap: "wrap",
    textAlign: "left",
  },
  descCondTitle: {
    fontWeight: 700,
    fontSize: 10,
    width: "70%",
    textAlign: "left",
  },
});
