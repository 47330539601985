// File: ./styles/loginstyles.js

import appSettings from "../appSettings/appSettings";
import { StyleSheet, Dimensions } from "react-native";

const { width, height } = Dimensions.get("window");

export const styles = StyleSheet.create({
  logoImage: {
    width: 170,
    height: 85,
    resizeMode: "contain",
    marginBottom: 15,
  },
  emptySpacer: {
    height: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // Ensure it fills the screen width
  },
  container: {
    width: width,
    backgroundColor: "#fff",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
  column: {
    height: height - 40,
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },

  //
  // LEFT COLUMN AREA START
  //
  leftColumn: {},
  gradientBackground: {
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    borderRadius: appSettings.generalStyling.BorderRadius,
    width: "95%",
  },
  //
  // LEFT COLUMN TOP AREA START
  //
  topBox: {
    flex: 3,
  },
  publisherArea: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  publishedByText: {
    marginLeft: 2,
    fontSize: 14,
    color: appSettings.color.Orange,
  },

  currentAuctTitle: {
    color: appSettings.color.Black,
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 15,
    textTransform: "uppercase",
  },
  currentCatTitle: {
    color: appSettings.color.darkGray,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 11,
  },
  //
  // LEFT COLUMN TOP AREA END
  //
  //
  // LEFT COLUMN BOTTOM AREA START
  //
  bottomBox: {
    flex: 7,
  },
  progressBarContainer: {
    height: 5,
    width: "80%",
    backgroundColor: appSettings.color.loadBarBgGray,
    borderRadius: 10,
  },
  progressBar: {
    height: "100%",
    backgroundColor: appSettings.color.Orange,
    borderRadius: 10,
  },
  lotsRemainingText: {
    color: appSettings.color.lightGray,
    fontSize: 11,
    fontFamily: "Roboto",
  },
  boldText: {
    fontWeight: 700,
    color: appSettings.color.Black,
  },
  //
  // LEFT COLUMN BOTTOM AREA END
  //
  //
  // MIDDLE COLUMN
  //
  middleColumn: {
    flex: 1.5,
    justifyContent: "space-between",
  },
  middleBottomBlock: {
    flex: 1,
    marginTop: 20,
  },
  //
  // MIDDLE COLUMN BOTTOM AREA END
  //
  //
  // RIGHT COLUMN
  //
  rightColumn: {
    flex: 1.5,
    justifyContent: "space-between",
  },
});
