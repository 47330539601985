import appSettings from "../../appSettings/appSettings";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  messageContainer: {
    width: "91%",
    padding: 15,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
  },
  messageText: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 12,
    color: "#000",
  },
  timestamp: {
    width: "8%",
    paddingRight: 10,
    fontSize: 9,
    fontWeight: 400,
    color: "#969696",
  },
});

// Define styles for each textType
export const messageStyles = {
  redBox: {
    backgroundColor: appSettings.color.Red,
    color: appSettings.color.White,
  },
  greenBox: {
    backgroundColor: appSettings.color.Green,
    color: appSettings.color.White,
  },
  blueBox: {
    backgroundColor: appSettings.color.Blue,
    color: appSettings.color.White,
  },
  orangeBox: {
    backgroundColor: appSettings.color.Orange,
    color: appSettings.color.Black,
  },
  blankBox: { backgroundColor: "transparent", color: appSettings.color.Black },
  grayBox: {
    backgroundColor: appSettings.color.lightGray,
    color: appSettings.color.Black,
  },
};
