import appSettings from "./../appSettings/appSettings";

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Text,
  Animated,
} from "react-native";
import { styles } from "./styles/productshowcasestyles";
import lotsData from "./auctionManagement/lotImporter";
import DataManager from "./dataManager/dataManager";
import { MaterialCommunityIcons, Fontisto } from "@expo/vector-icons";

const ProductShowcase = () => {
  const {
    currentLiveAuction,
    currentCategory,
    publishedBy,
    currentAuctions,
    totalAuctions,
    lotsRemaining,
  } = DataManager();

  const liveLot = lotsData.find((lot) => lot.status === "Live");
  if (!liveLot) return null;

  // Include the main image in the secondary images array for display
  // This step ensures the main image is always part of the secondary images list
  const allImages = [
    liveLot.image,
    ...liveLot.secondaryImages.filter((img) => img !== liveLot.image),
  ];

  const [mainImage, setMainImage] = useState(liveLot.image);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Default to the main image

  // Effect to set the main image based on selectedImageIndex
  useEffect(() => {
    setMainImage(allImages[selectedImageIndex]);
  }, [selectedImageIndex, allImages]);

  const jumpValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const jumpAnimation = Animated.loop(
      Animated.sequence([
        Animated.timing(jumpValue, {
          toValue: 8,
          duration: 700,
          useNativeDriver: true,
        }),
        Animated.timing(jumpValue, {
          toValue: 0,
          duration: 700,
          useNativeDriver: true,
        }),
      ])
    );

    jumpAnimation.start();

    return () => jumpAnimation.stop();
  }, []);

  const animatedStyle = {
    transform: [{ translateY: jumpValue }],
  };

  return (
    <View style={styles.container}>
      <View style={styles.topArea}>
        <View style={styles.lotIdBlock}>
          <Text style={styles.lotIdText}>Lot {currentAuctions}</Text>
        </View>
        <Text style={styles.lotTitle}>{liveLot.title}</Text>
        <View style={styles.publisherArea}>
          <MaterialCommunityIcons
            name="account-outline"
            size={18}
            color={appSettings.color.Orange}
          />
          <Text style={styles.publishedByText}>{publishedBy}</Text>
        </View>
        <Text style={styles.lotCategory}>{liveLot.category}</Text>
      </View>
      <ScrollView
        vertical
        showsVerticalScrollIndicator={false}
        style={styles.imageAndDesc}
      >
        <View style={styles.imageContainerBlock}>
          <Image source={{ uri: mainImage }} style={styles.mainImage} />
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={styles.secondaryImagesContainer}
          >
            {allImages.map((img, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => setSelectedImageIndex(index)}
              >
                <Image
                  source={{ uri: img }}
                  style={
                    index === selectedImageIndex
                      ? styles.selectedSecondaryImage
                      : styles.secondaryImage
                  }
                />
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
        <View style={styles.descriptionBlock}>
          <Text>ITEM DESCRIPTION</Text>
          <Text> </Text>
          <Animated.View style={animatedStyle}>
            <Fontisto name="angle-dobule-down" size={24} color="black" />
          </Animated.View>
          <Text> </Text>
          <Text> </Text>
          <Text style={styles.descCondTitle}>DESCRIPTION</Text>
          <Text style={styles.longDescriptionTxt}>
            {liveLot.longDescription}
          </Text>
          <Text> </Text>
          <Text style={styles.descCondTitle}>CONDITION</Text>
          <Text style={styles.longDescriptionTxt}>{liveLot.condition}</Text>
          <Text> </Text>
          <Text style={styles.descCondTitle}>CATEGORY</Text>
          <Text style={styles.longDescriptionTxt}>{liveLot.category}</Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default ProductShowcase;
