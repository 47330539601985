// chatManager.js

// A mock function to simulate fetching messages from a server or database
const fetchMessages = () => {
  return Promise.resolve([
    { id: 1, text: "Fair Warning", timestamp: new Date().getTime() },
    {
      id: 2,
      text: "Lot 101 has been sold to Alice",
      timestamp: new Date().getTime(),
      textType: "redBox",
    },
    { id: 3, text: "Ask Price Changed: $50", timestamp: new Date().getTime() },
    {
      id: 4,
      text: "Last Call... This lot is about to close...",
      timestamp: new Date().getTime(),
      textType: "greenBox",
    },
    {
      id: 5,
      text: "Don’t Miss The Golden Necklace",
      timestamp: new Date().getTime(),
      textType: "blueBox",
    },
    {
      id: 6,
      text: "Custom message from Auctioneer will be displayed like this 😄",
      timestamp: new Date().getTime(),
      textType: "blankBox",
    },
    {
      id: 7,
      text: "Lot 102 Opened - Ask $10",
      timestamp: new Date().getTime(),
      textType: "blueBox",
    },
    {
      id: 8,
      text: "Going Once... Going Twice...",
      timestamp: new Date().getTime(),
      textType: "orangeBox",
    },
    {
      id: 9,
      text: "$145: Completing Bid",
      timestamp: new Date().getTime(),
      textType: "grayBox",
    },
    {
      id: 10,
      text: "New green box!",
      timestamp: new Date().getTime(),
      textType: "greenBox",
    },
    {
      id: 11,
      text: "New Red Box",
      timestamp: new Date().getTime(),
      textType: "redBox",
    },
    {
      id: 12,
      text: "New Blue Box",
      timestamp: new Date().getTime(),
      textType: "blueBox",
    },
    {
      id: 13,
      text: "New Orange Box",
      timestamp: new Date().getTime(),
      textType: "orangeBox",
    },
    {
      id: 14,
      text: "New Gray Box",
      timestamp: new Date().getTime(),
      textType: "grayBox",
    },
    {
      id: 15,
      text: "Last New Blank Box",
      timestamp: new Date().getTime(),
      textType: "blankBox",
    },
  ]);
};

class ChatManager {
  constructor() {
    this.chatHistory = [];
    this.listeners = [];
  }

  init() {
    // Fetch messages and set up the initial chat history
    fetchMessages().then((messages) => {
      this.chatHistory = messages;
      this.notifyListeners();
    });
  }

  // Method to simulate sending a message
  sendMessage(text) {
    const message = {
      id: this.chatHistory.length + 1,
      text,
      timestamp: new Date().getTime(),
    };
    this.chatHistory.push(message);
    this.notifyListeners();
  }

  // Register a listener to be called whenever the chat history changes
  addListener(listener) {
    this.listeners.push(listener);
  }

  // Remove a listener
  removeListener(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  // Notify all listeners of the updated chat history
  notifyListeners() {
    this.listeners.forEach((listener) => listener(this.chatHistory));
  }

  // Get the current chat history
  getHistory() {
    return this.chatHistory;
  }
}

export default new ChatManager();
