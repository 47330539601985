import React, { useState, useEffect, useRef } from "react";
import { View, Text, TextInput, Button, TouchableOpacity } from "react-native";
import { styles } from "./styles/biddingblockstyles";
import DataManager from "../components/dataManager/dataManager";

const BiddingBlock = () => {
  const { currentBid, incrementBid, setCurrentBid } = DataManager();

  const [newBid, setNewBid] = useState(currentBid.toString()); // Local state to handle user input
  const [isValidBid, setIsValidBid] = useState(true); // New state to track if the bid is valid

  // Ref for the bid input text input
  const bidInputRef = useRef(null);

  useEffect(() => {
    setNewBid(currentBid.toString());
  }, [currentBid]);

  const placeBid = () => {
    const minimumBid = parseFloat(currentBid) + incrementBid;
    if (parseFloat(newBid) >= minimumBid) {
      setCurrentBid(parseFloat(newBid));
      setIsValidBid(true); // Reset the bid validation state
    } else {
      setIsValidBid(false);
    }
  };

  // Function to format the bid amount for display
  const formatBidForDisplay = (bid) => {
    // Separate the integer and decimal parts
    const parts = parseFloat(bid).toFixed(2).split(".");
    // Add commas for thousands
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Return the formatted bid amount
    return `$${parts.join(".")}`;
  };

  // Function to handle text input change
  const handleTextInputChange = (text) => {
    // Strip any non-numeric characters and update the newBid state
    setNewBid(text.replace(/[^0-9.]/g, ""));
    setIsValidBid(true);

    // Reset cursor position to the end
    bidInputRef.current.setNativeProps({
      selection: { start: text.length, end: text.length },
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.incrementContainer}>
        <Text style={styles.incrementText}>Increment Bid</Text>
        <Text style={styles.estimateText}>Est. $104 - $135</Text>
      </View>
      <View style={styles.bidContainer}>
        {/* Display the current bid */}
        <TextInput
          style={styles.bidIncrement}
          value={`$${incrementBid.toFixed(2)}`}
          editable={false}
        />
        {/* Display the bid input with formatted value */}
        <TextInput
          ref={bidInputRef}
          style={styles.bidInput}
          value={formatBidForDisplay(newBid)}
          onChangeText={handleTextInputChange}
          keyboardType="numeric"
        />
      </View>
      {!isValidBid && (
        <Text style={styles.errorText}>
          Bid must be higher or equal to minimum bid ${" "}
          {(parseFloat(currentBid) + incrementBid).toFixed(2)}
        </Text>
      )}
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.bidNowButton} onPress={placeBid}>
          <Text style={styles.bidNowButtonText}>Bid Now</Text>
        </TouchableOpacity>
      </View>
      <TextInput
        value={currentBid}
        onChangeText={(text) => {
          setCurrentBid(text);
        }}
        keyboardType="numeric"
      />
    </View>
  );
};

export default BiddingBlock;
