import appSettings from "./appSettings/appSettings";
import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  Image,
  Animated,
} from "react-native";
import { styles } from "./styles/loginstyles";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showFailedNotification, setShowFailedNotification] = useState(false);
  const [fadeAnimSuccess] = useState(new Animated.Value(0));
  const [fadeAnimFailed] = useState(new Animated.Value(0));

  const handleLogin = async () => {
    try {
      // Send login request to WordPress site
      const response = await fetch(appSettings.wpApiJWTLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Login successful
        setLoginStatus("success");
        // Extract email from token data
        setEmail(data.user_email);
        // Fetch user profile data to get the role
        const profileResponse = await fetch(appSettings.wpApiFetchURL, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        const profileData = await profileResponse.json();
        console.log("Profile Data:", profileData); // Log profile data to inspect its structure

        // Code snippet for extracting the role
        if (
          profileData.woocommerce_meta &&
          profileData.woocommerce_meta.customer_roles
        ) {
          // Assuming the role is an array and we take the first role
          setRole(profileData.woocommerce_meta.customer_roles[0]);
        } else {
          setRole("Role not available");
        }

        // Show success notification
        setShowSuccessNotification(true);

        // Store token securely for later use
        await AsyncStorage.setItem("token", data.token);

        setTimeout(() => {
          setShowSuccessNotification(false);
          // Redirect to /Auctions after 3 seconds
          window.location.href = `/Auctions`;
        }, 3000);

        // Redirect to Auctions screen
        setTimeout(() => {
          setShowSuccessNotification(false);
          // Use navigation to navigate to the Auctions screen
          // Example: navigation.navigate('Auctions');
        }, 3000);
      } else {
        // Login failed
        setLoginStatus("failed");
        Alert.alert("Login Failed", data.message);

        // Show failed notification
        setShowFailedNotification(true);
        // Hide failed notification after 5 seconds
        setTimeout(() => {
          setShowFailedNotification(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginStatus("error");
      Alert.alert("Error", "An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    if (showSuccessNotification) {
      // Fade in animation for success notification
      Animated.timing(fadeAnimSuccess, {
        toValue: 1,
        duration: 800,
        useNativeDriver: false,
      }).start(() => {
        // Hide success notification after 5 seconds
        setTimeout(() => {
          Animated.timing(fadeAnimSuccess, {
            toValue: 0,
            duration: 800,
            useNativeDriver: false,
          }).start();
        }, 5000);
      });
    }
  }, [showSuccessNotification]);

  useEffect(() => {
    if (showFailedNotification) {
      // Fade in animation for failed notification
      Animated.timing(fadeAnimFailed, {
        toValue: 1,
        duration: 800,
        useNativeDriver: false,
      }).start(() => {
        // Hide failed notification after 5 seconds
        setTimeout(() => {
          Animated.timing(fadeAnimFailed, {
            toValue: 0,
            duration: 800,
            useNativeDriver: false,
          }).start();
        }, 5000);
      });
    }
  }, [showFailedNotification]);

  return (
    <View style={styles.container}>
      <Image source={{ uri: appSettings.logoImage }} style={styles.image} />
      <Text style={styles.title}>Login</Text>
      <TextInput
        style={styles.input}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
      />
      <TextInput
        style={styles.input}
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />
      <TouchableOpacity style={styles.button} onPress={handleLogin}>
        <Text style={styles.buttonText}>Login</Text>
      </TouchableOpacity>
      <Animated.View
        style={[
          styles.notification,
          { backgroundColor: "#25D15F", opacity: fadeAnimSuccess },
        ]}
      >
        <Text style={styles.notificationText}>Login Successful!</Text>
      </Animated.View>
      <Animated.View
        style={[
          styles.notification,
          {
            backgroundColor: appSettings.color.Orange,
            opacity: fadeAnimFailed,
          },
        ]}
      >
        <Text style={styles.notificationText}>Login Failed!</Text>
      </Animated.View>
    </View>
  );
};

export default Login;
