// File: AuctionLotsList.js

import appSettings from "../appSettings/appSettings";
import React from "react";
import { View, Text, Image, ScrollView } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { styles } from "./styles/auctionlotsliststyles";
import lotsData from "./auctionManagement/lotImporter";

const AuctionLotCard = ({ lot }) => {
  // Determine the appropriate style based on the lot's status
  let statusStyle;
  let cardBorderStyle;
  switch (lot.status) {
    case "Live":
      statusStyle = styles.live;
      cardBorderStyle = styles.liveCard;
      break;
    case "Sold":
      statusStyle = styles.sold;
      cardBorderStyle = styles.soldCard;
      break;
    case "Upcoming":
      statusStyle = styles.upcoming;
      cardBorderStyle = styles.upcomingCard;
      break;
    case "Unsold":
      statusStyle = styles.unsold;
      cardBorderStyle = styles.unsoldCard;
      break;
    default:
      statusStyle = null;
      cardBorderStyle = null;
  }

  return (
    <View style={[styles.card, cardBorderStyle]}>
      <Image source={{ uri: lot.image }} style={styles.image} />
      <View style={styles.infoContainer}>
        <View style={[styles.statusContainer, statusStyle]}>
          <Text style={styles.liveLabel}>{lot.status}</Text>
        </View>
        <Text style={styles.title}>{lot.title}</Text>
        <Text style={styles.category}>{lot.category}</Text>
        <View style={styles.priceContainer}>
          <Text style={styles.price}>{lot.price}</Text>
          <Text style={styles.bids}>{lot.bids}</Text>
        </View>
      </View>
    </View>
  );
};

const AuctionLotsList = () => {
  const rearrangedLots = [];
  let lastSoldOrUnsold = null;
  let nextLive = null;

  // Rearrange the lotsData array
  for (let i = lotsData.length - 1; i >= 0; i--) {
    const lot = lotsData[i];
    switch (lot.status) {
      case "Sold":
      case "Unsold":
        lastSoldOrUnsold = lot;
        break;
      case "Live":
        nextLive = lot;
        break;
      default:
        rearrangedLots.unshift(lot); // Add upcoming items to the beginning
    }
  }

  // Push the next live item and the last sold or unsold item
  if (nextLive) {
    rearrangedLots.unshift(nextLive);
  }
  if (lastSoldOrUnsold) {
    rearrangedLots.unshift(lastSoldOrUnsold);
  }

  return (
    <ScrollView
      style={{ height: "100%", width: "100%" }}
      showsVerticalScrollIndicator={false}
    >
      {rearrangedLots.map((lot) => (
        <AuctionLotCard key={lot.id} lot={lot} />
      ))}
    </ScrollView>
  );
};

export default AuctionLotsList;
