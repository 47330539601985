import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/Home";
import Subscreen from "./screens/Subscreen";
import Login from "./screens/Login";
import Auctions from "./screens/Auctions";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/Subscreen" element={<Subscreen />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Auctions" element={<Auctions />} />
      </Routes>
    </Router>
  );
}
