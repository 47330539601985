// File: ./styles/loginstyles.js
import appSettings from "../appSettings/appSettings";
import { StyleSheet, Dimensions } from "react-native";

// Get the device screen dimensions
const { width } = Dimensions.get("window");

// Determine the input width based on the screen size
let inputWidth;
if (width >= 768 && width < 1024) {
  // Tablet
  inputWidth = "60%";
} else if (width >= 1024) {
  // Desktop
  inputWidth = 250;
} else {
  // Mobile
  inputWidth = "80%";
}

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: inputWidth, // Set the width based on device screen size
    height: 40,
    borderColor: appSettings.color.darkGray,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: appSettings.color.Orange, // Background color
    paddingVertical: 10,
    paddingHorizontal: 50,
    borderRadius: 5, // Border radius
  },
  buttonText: {
    color: appSettings.color.Black, // Text color
    fontWeight: "bold", // Font weight
    fontSize: 17, // Font size
    fontFamily: "Roboto", // Font family
  },
  notification: {
    position: "absolute",
    top: 20, // Adjust top position as needed
    left: 20, // Adjust left position as needed
    backgroundColor: appSettings.color.Green,
    padding: 10,
    borderRadius: 5,
    zIndex: 1, // Ensure notifications appear above other elements
  },
  notificationText: {
    color: appSettings.color.White,
    fontWeight: "bold",
    fontSize: 16,
  },
  userInfoContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  userInfoScroll: {
    maxHeight: 200, // Adjust the height as needed
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  image: {
    width: 300,
    height: 200,
    marginBottom: 20,
    resizeMode: "contain", // Ensures the image is contained within its specified width and height
  },
});
