import React, { useState, useEffect, useRef } from "react"; // Added missing imports here
import { View, Text, ScrollView } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { styles, messageStyles } from "./styles/chatblockstyles";
import lotsData from "./auctionManagement/lotImporter";
import ChatManager from "./chatManager/chatManager"; // Corrected the import name

const ChatBlock = () => {
  const [messages, setMessages] = useState([]);
  const scrollViewRef = useRef(); // Create a ref for the ScrollView

  useEffect(() => {
    ChatManager.init(); // Initialize the chat manager
    ChatManager.addListener(setMessages); // Subscribe to updates

    return () => {
      ChatManager.removeListener(setMessages); // Unsubscribe from updates
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom every time messages change
    scrollViewRef.current?.scrollToEnd({ animated: false });
  }, [messages]);

  const getMessageStyle = (textType) => {
    return messageStyles[textType] || messageStyles.blankBox;
  };

  // Function to format the timestamp
  const formatTimestamp = (timestamp) => {
    // Assuming timestamp is in milliseconds
    const date = new Date(timestamp);
    // Format as you prefer, this is just an example
    return `${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <View style={styles.container}>
      <ScrollView
        ref={scrollViewRef} // Assign the ref to the ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainer}
        inverted
        showsVerticalScrollIndicator={false}
      >
        {messages.map((message, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 5,
            }}
          >
            <Text style={styles.timestamp}>
              {formatTimestamp(message.timestamp)}
            </Text>
            <View
              style={[
                styles.messageContainer,
                getMessageStyle(message.textType),
              ]}
            >
              <Text style={{ color: getMessageStyle(message.textType).color }}>
                {message.text}
              </Text>
            </View>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

export default ChatBlock;
