import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Linking,
} from "react-native";

export default function HomeScreen() {
  const goToSubScreen = () => {
    window.location.href = "/Subscreen";
  };
  const goToLogin = () => {
    window.location.href = "/Login";
  };

  return (
    <View style={styles.container}>
      <Text>Welcome to Home Screen!</Text>
      <TouchableOpacity onPress={goToSubScreen}>
        <View style={styles.button}>
          <Text style={styles.buttonText}>Go to SubScreen</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={goToLogin}>
        <View style={styles.button}>
          <Text style={styles.buttonText}>Go to Login</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: 20,
    backgroundColor: "blue",
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
});
