import appSettings from "../../appSettings/appSettings";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    borderRadius: 6,
    overflow: "hidden",
    flexDirection: "row",
    backgroundColor: "white",
    borderColor: appSettings.color.lightGray,
    borderWidth: 2,
    borderLeftWidth: 4,
    marginVertical: 4,
    width: "100%",
    paddingLeft: 10,
  },
  image: {
    width: 100, // Adjust the width to your requirement
    height: 100, // Adjust the height to your requirement
    resizeMode: "cover",
    borderRadius: appSettings.generalStyling.BorderRadius,
  },
  infoContainer: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
  },
  statusContainer: {
    width: "fit-content",
    alignItems: "flex-start",
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    color: appSettings.color.White,
    borderRadius: 5,
  },
  liveLabel: {
    color: appSettings.color.White,
    fontWeight: 700,
  },
  liveCard: {
    borderLeftColor: appSettings.color.Red,
  },
  unsoldCard: {
    borderLeftColor: appSettings.color.lightGray,
  },
  soldCard: {
    borderLeftColor: appSettings.color.darkGray,
  },
  upcomingCard: {
    borderLeftColor: appSettings.color.Blue,
  },
  sold: {
    backgroundColor: appSettings.color.darkGray,
  },
  unsold: {
    backgroundColor: appSettings.color.lightGray,
  },
  live: {
    backgroundColor: appSettings.color.Red,
  },
  upcoming: {
    backgroundColor: appSettings.color.Blue,
  },
  title: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 3,
  },
  category: {
    fontSize: 14,
    color: "#555",
    marginBottom: 5,
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  price: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
  },
  bids: {
    fontSize: 14,
    color: "#555",
  },
});
