import React, { useState, useEffect } from "react";
import lotsData from "../auctionManagement/lotImporter";
import useSocketProductUpdates from "./useSocketProductUpdates";

const DataManager = () => {
  useSocketProductUpdates();
  console.log("Hello");
  const [currentLiveAuction, setCurrentLiveAuction] = useState(
    "No Live Auctions..."
  );
  const [currentCategory, setCurrentCategory] = useState("No Category...");
  const [publishedBy, setPublishedBy] = useState("No Publisher...");

  const [currentAuctions, setCurrentAuctions] = useState(0);
  const [totalAuctions, setTotalAuctions] = useState(0);
  const [lotsRemaining, setLotsRemaining] = useState(0);
  const [currentBid, setCurrentBid] = useState(3500);
  const [incrementBid, setIncrementBid] = useState(10);

  useEffect(() => {
    // Find the live lot
    const liveLot = lotsData.find((lot) => lot.status === "Live");

    // If live lot found, update current category to live lot category
    if (liveLot) {
      setCurrentCategory(liveLot.category);
      setCurrentAuctions(liveLot.id);
      setCurrentLiveAuction(liveLot.title);
      setPublishedBy(liveLot.publishedBy);
    }

    // Count total number of lots
    setTotalAuctions(lotsData.length);

    // Count number of lots with "Upcoming" status
    const upcomingLotsCount = lotsData.filter(
      (lot) => lot.status === "Upcoming"
    ).length;

    // Include live lot if it exists
    if (liveLot) {
      setLotsRemaining(upcomingLotsCount + 1);
    } else {
      setLotsRemaining(upcomingLotsCount);
    }
  }, []);

  return {
    currentLiveAuction,
    currentCategory,
    publishedBy,
    currentAuctions,
    totalAuctions,
    lotsRemaining,
    currentBid,
    incrementBid,
    setCurrentBid,
  };
};

export default DataManager;
